import React from 'react';
import styled from '@emotion/styled';
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";

import Headings from '@components/Headings';
import Image, { ImagePlaceholder } from '@components/Image';

import mediaqueries from '@styles/media';
import { IArticle, IAuthor } from '@types';

import ArticleAuthors from './Article.Authors';
import AudioBar from '../others/Audio.Bar'

interface ArticleHeroProps {
  article: IArticle;
  authors: IAuthor[];
}

const ArticleHero: React.FC<ArticleHeroProps> = ({ article, authors }) => {
  const hasCoAUthors = authors.length > 1;
  const imageSource = article.hero.narrow;
  const hasHeroImage =
    imageSource &&
    Object.keys(imageSource).length !== 0 &&
    imageSource.constructor === Object;

  return (
    <Hero backgroundColor={article.backgroundColor} hasHeroImage={hasHeroImage}>
      <Header>
        <HeroHeading dark={article.dark}>{article.title}</HeroHeading>
        { !article.landingPage && 
          <HeroSubtitle hasCoAUthors={hasCoAUthors} dark={article.dark}>
            <ArticleAuthors authors={authors} />
            <ArticleMeta hasCoAUthors={hasCoAUthors}>
              {article.date}
            </ArticleMeta>
          </HeroSubtitle>
        }
        <Excerpt dark={article.dark}>{article.excerpt}</Excerpt>

        { article.audio && 
          <AudioBar
            title="Voice, louder! project"
            audioURL={article.audio}
          />
        }
      </Header>

      {hasHeroImage &&
        <HeroImage>
            <Image src={imageSource} />
        </HeroImage>
      }
    </Hero>
  );
};

export default ArticleHero;

const ARTICLE_WIDTH = css`
  width: 100%;
  max-width: 680px;

  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
  `};

  ${mediaqueries.phablet`
    padding: 0 20px;
  `};
`;
const Hero = styled.div<{ 
  backgroundColor: string, 
  hasHeroImage: boolean,
}>`

  background: ${p => p.backgroundColor ? p.theme.colors[p.backgroundColor]: p.theme.colors.decorColor05};
  padding-bottom: 96px;

  ${mediaqueries.tablet`
    padding-bottom: 72px;
  `}

  ${p => `
    ${!p.hasHeroImage &&
      `
        padding-bottom: 4px;
      `
    }
  `}

`;

const ArticleMeta = styled.div<{ hasCoAUthors: boolean }>`
  margin-left: ${p => (p.hasCoAUthors ? '10px' : '0')};

  ${mediaqueries.phablet`
    margin-left: 0;
  `}
`;

const Header = styled.header<{ dark?: boolean }>`
  position: relative;
  z-index: 9;
  margin: 0 auto 56px;
  padding-top: 200px;
  max-width: 749px;

  ${mediaqueries.desktop`
    max-width: calc(507px + 53px);
    padding-top: 180px;
  `}

  ${mediaqueries.tablet`
    max-width: 480px;
  `}

`;

const HeroHeading = styled(Headings.h1)<{dark?: boolean}>`
  font-family: ${p => p.theme.fonts.title};
  font-weight: ${p => p.theme.fontsWeight.bold};
  color: ${p => (p.dark ? p.theme.colors.textPrimaryDark : p.theme.colors.primary)};
  font-size: 100px;
  margin-bottom: 25px;
  text-align: center;

  ${mediaqueries.tablet`
    margin-bottom: 8px;
    padding: 0 16px;
  `}
`;

const Excerpt = styled(Headings.h3)<{dark?: boolean}>`
  font-family: ${p => p.theme.fonts.titleEm};
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -1.5px;
  color: ${p => (p.dark ? p.theme.colors.textSecondaryDark : p.theme.colors.secondary)};
  margin: 24px 0;
  font-style: italic;
  font-weight: ${p => p.theme.fontsWeight.regular};
  text-align: center;

  ${mediaqueries.tablet`
    margin: 8px 0;
    padding: 0 16px;
    line-height: 1.3;
  `}
`;

const HeroSubtitle = styled.div<{ hasCoAUthors: boolean, dark?: boolean }>`
  position: relative;
  display: flex;
  font-size: 14px;
  // color: ${p => p.theme.colors.secondary};
  color: ${p => (p.dark ? p.theme.colors.textSecondaryDark : p.theme.colors.secondary)};
  align-items: center;
  justify-content: center;
  
  ${p => mediaqueries.phablet`
    flex-direction: column;
    align-items: left;

    ${p.hasCoAUthors &&
      `
        &::before {
          content: '';
          position: absolute;
          left: -20px;
          right: -20px;
          top: -10px;
          bottom: -10px;
          border: 1px solid ${p.theme.colors.horizontalRule};
          opacity: 0.5;
          border-radius: 5px;
        }
    `}


    strong {
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
    }
  `}
`;

const HeroImage = styled.div`
  position: relative;
  z-index: 1;
  ${ARTICLE_WIDTH};
  overflow: hidden;
  margin: 0 auto;
`;
